import { AccountType, loginSeletor } from '@slice/login'
import { Button } from '@starter/component/Button'
import { colors } from '@starter/themes/colors'
import {
  FlatList,
  Pressable,
  SafeAreaView,
  StyleSheet,
  View
  } from 'react-native'
import { Icons } from '@starter/themes/icons'
import { IShop } from '@slice/shop'
import { LoggedInStackScreenProps } from '../navigation/types'
import { orderSlice } from '@slice/order'
import { Row } from '@starter/component/Row'
import { size } from '@starter/themes/size'
import { Text } from '@starter/component/Text'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useShops } from '../hooks/useShops'


export default function SelectShopScreen({ navigation }: LoggedInStackScreenProps<'SelectShop'>) {
  const dispatch = useDispatch()
  const { shops } = useShops()
  const { shopId, accountType } = useSelector(loginSeletor)
  const [selected, setSelected] = useState<IShop | undefined>(shops.find((s) => s.KY === shopId))

  useEffect(() => {
    setSelected(shops.find((s) => s.KY === shopId))
  }, [shops])

  const onPressConfirm = () => {
    if (selected) {
      dispatch(orderSlice.actions.addChart({ chart: { shopId: selected.KY, items: [], supplierId: '' } }))
      navigation.navigate('SelectSupplier')
    }
  }

  const onPress = (item: IShop) => {
    if (accountType !== AccountType.SHOP_ADMIN) {
      setSelected(item)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={shops}
        renderItem={({ item }) => {
          return (
            <Pressable onPress={() => onPress(item)}>
              <View style={styles.cell}>
                <Row
                  containerStyle={styles.row}
                  title={String(item.KY)}
                  description={item.D1L}
                  leftIcon={
                    selected?.KY === item.KY
                      ? Icons({ name: 'checked', color: colors.primary })
                      : Icons({ name: 'unchecked' })
                  }
                ></Row>
                <Text.Caption style={{ textAlign: 'right', paddingRight: size[4], color: colors.gray600 }}>
                  {item.D2L}
                </Text.Caption>
              </View>
            </Pressable>
          )
        }}
      />
      <Button disabled={!selected} style={styles.buttonContainer} title='確定' onPress={onPressConfirm} />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  cell: {
    borderBottomWidth: 1,
    borderBottomColor: colors.gray400,
    paddingBottom: size[2],
    backgroundColor: colors.white,
  },
  row: {
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  buttonContainer: {
    padding: size[4],
    borderTopWidth: 1,
    borderColor: colors.gray800,
  },
})
