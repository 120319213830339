import { colors } from '@starter/themes/colors'
import { FlatList, StyleSheet, View } from 'react-native'
import { LoggedInBottomTabProps } from '../navigation/types'
import { Row } from '@starter/component/Row'
import { size } from '@starter/themes/size'
import { Text } from '@starter/component/Text'
import { useShops } from '../hooks/useShops'


export default function ShopsScreen({ navigation }: LoggedInBottomTabProps<'Shops'>) {
  const { shops } = useShops()

  return (
    <View style={styles.container}>
      <FlatList
        data={shops}
        renderItem={({ item }) => {
          return (
            <View style={styles.cell}>
              <Row containerStyle={styles.row} title={String(item.KY)} description={item.D1L}></Row>
              <Text.Caption style={{ textAlign: 'right', paddingRight: size[4], color: colors.gray600 }}>
                {item.D2L}
              </Text.Caption>
            </View>
          )
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cell: {
    borderBottomWidth: 1,
    borderBottomColor: colors.gray400,
    paddingBottom: size[2],
    backgroundColor: colors.white,
  },
  row: {
    borderBottomWidth: 0,
    paddingBottom: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
