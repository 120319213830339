import {
  SafeAreaView,
  StyleSheet,
} from 'react-native'
import { orderSeletor, orderSlice } from '@slice/order'
import { useDispatch, useSelector } from 'react-redux'

import { IChartItem } from '@slice/orderTyps'
import { LoggedInStackScreenProps } from '../navigation/types'
import { ProductsSelectionController } from '@components/ProductsSelectionController'
import { colors } from '@starter'

export default function SelectProductScreen({ navigation }: LoggedInStackScreenProps<'SelectProduct'>) {
  const { chart } = useSelector(orderSeletor)
  const dispatch = useDispatch()


  const onPressConfirm = (items: IChartItem[]) => {
    if (!chart) {
      return
    }
    dispatch(orderSlice.actions.addChart({ chart: { ...chart, items: items } }))
    navigation.navigate('OrderReview', { docId: '' })
  }

  return (
    <SafeAreaView style={styles.container}>
      <ProductsSelectionController shopId={chart?.shopId ?? ''} supplierId={chart?.supplierId ?? ''} currentItems={[]} onPressConfirm={onPressConfirm} />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
})
