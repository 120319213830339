import { StoreStatus, api } from '@starter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from '@slice'
import { loginActions } from '@slice/login'

export interface IShop {
  KY: string // shop ID: 1
  D1L: string // Chinese name: (01)元朗阜財街73號
  D2L: string
}

interface IGetShopResponse {
  result: number
  message: string
  shoplist: IShop[]
}

export type ShopState = {
  status: StoreStatus
  shops: IShop[]
}

export const shopApiGet = createAsyncThunk('shop/api/get', async (_, { rejectWithValue, dispatch, getState }) => {
  const { login } = getState() as RootState
  const response = await api().get<IGetShopResponse>('Shop_GetListV2_J', { sSUID: login.sSUID, sSUPW: login.sSUPW })
  if (response && response.data?.result === 1) {
    return response.data.shoplist
  } else {
    dispatch(loginActions.logout())
    throw rejectWithValue({})
  }
})

const initialState: ShopState = {
  status: 'idle',
  shops: [],
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
    resetLogin: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(shopApiGet.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(shopApiGet.fulfilled, (state, action) => {
        state.status = 'success'
        state.shops = action.payload
      })
      .addCase(shopApiGet.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const shopActions = shopSlice.actions
export const shopSeletor = (state: RootState) => state.shop
