import * as React from 'react';
import LinkingConfiguration from './LinkingConfiguration';
import LoginScreen from '@screens/LoginScreen';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import OrderReview from '@screens/OrderReview';
import OrdersScreen from '@screens/OrdersScreen';
import ProfileScreen from '@screens/ProfileScreen';
import SafeRecordUploadStatusesScreen from '@screens/SafeRecordUploadStatusesScreen';
import SelectProductScreen from '@screens/SelectProductScreen';
import SelectShopScreen from '@screens/SelectShopScreen';
import SelectSupplierScreen from '@screens/SelectSupplierScreen';
import ShopsScreen from '@screens/ShopsScreen';
import SuppliersScreen from '@screens/SuppliersScreen';
import UsersScreen from '@screens/UsersScreen';
import { AccountType } from '@slice/login';
import { AntDesign, Feather, Fontisto } from '@expo/vector-icons';
import { colors, Icons, size } from '@starter';
import { ColorSchemeName, Pressable } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import {
  LoggedInBottomTabParamList,
  LoggedInBottomTabProps,
  LoggedInStackParamList,
  RootStackParamList
  } from './types';



/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */

export default function Navigation({ colorScheme, isLoggedIn, accountType }: { colorScheme: ColorSchemeName; isLoggedIn: boolean, accountType: AccountType }) {
  return (
    <NavigationContainer linking={LinkingConfiguration} theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator isLoggedIn={isLoggedIn} accountType={accountType} />
    </NavigationContainer>
  )
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>()

function RootNavigator({ isLoggedIn, accountType }: { isLoggedIn: boolean, accountType: AccountType }) {
  return (
    <Stack.Navigator>
      {!isLoggedIn && <Stack.Screen name='Login' component={LoginScreen} options={{ headerShown: false }} />}
      {isLoggedIn && <Stack.Screen name='LoggedIn' component={LoggedInNavigation({ accountType })} options={{ headerShown: false }} />}
      <Stack.Screen name='NotFound' component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name='Modal' component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  )
}

const LoggedInStack = createNativeStackNavigator<LoggedInStackParamList>()

const LoggedInNavigation = ({ accountType }: { accountType: AccountType }) => () => {
  return (
    <LoggedInStack.Navigator>
      <LoggedInStack.Screen name='Root' component={BottomTabNavigator} options={{ headerShown: false }} />
      <LoggedInStack.Screen
        name='SelectShop'
        component={SelectShopScreen}
        options={{ title: '選擇店舖', headerBackTitle: '', headerTintColor: colors.primary }}
      />
      <LoggedInStack.Screen
        name='SelectSupplier'
        component={SelectSupplierScreen}
        options={{ title: '選擇供應商', headerBackTitle: '', headerTintColor: colors.primary }}
      />
      <LoggedInStack.Screen
        name='SelectProduct'
        component={SelectProductScreen}
        options={{ title: '選擇產品', headerBackTitle: '', headerTintColor: colors.primary }}
      />
      <LoggedInStack.Screen
        name='OrderReview'
        component={OrderReview}
        options={{ title: '訂單', headerBackTitle: '', headerTintColor: colors.primary }}
      />
      {accountType === AccountType.ADMIN && <LoggedInStack.Screen
        name='Users'
        component={UsersScreen}
        options={{ title: '帳戶', headerBackTitle: '', headerTintColor: colors.primary }}
      />}
      <LoggedInStack.Screen
        name='SafeRecordUploadStatuses'
        component={SafeRecordUploadStatusesScreen}
        options={{ title: '安全量更新記錄', headerBackTitle: '', headerTintColor: colors.primary }}
      />
    </LoggedInStack.Navigator>
  )
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<LoggedInBottomTabParamList>()

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      initialRouteName='TabOne'
      screenOptions={{
        tabBarActiveTintColor: colors.primaryDark,
        headerStyle: {
          backgroundColor: colors.primaryDark,
        },
        headerTitleStyle: {
          color: colors.white,
        },
      }}
      backBehavior='history'
    >
      <BottomTab.Screen
        name='Orders'
        component={OrdersScreen}
        options={({ navigation }: LoggedInBottomTabProps<'Orders'>) => ({
          title: '訂單',
          tabBarIcon: ({ color }) => <AntDesign name='form' size={size[6]} color={color} />,
          headerRight: () => (
            <Pressable style={{ marginRight: size[4] }} onPress={() => navigation.navigate('SelectShop')}>
              <AntDesign name='plus' size={size[6]} color={colors.white} />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name='Shops'
        component={ShopsScreen}
        options={{
          title: '店舖',
          tabBarIcon: ({ color }) => <Fontisto name='shopping-store' size={size[5]} color={color} />,
        }}
      />
      <BottomTab.Screen
        name='Suppliers'
        component={SuppliersScreen}
        options={{ title: '供應商', tabBarIcon: ({ color }) => <Feather name='coffee' size={size[6]} color={color} /> }}
      />
      <BottomTab.Screen
        name='Profile'
        component={ProfileScreen}
        options={{ title: '用戶資料', tabBarIcon: ({ color }) => <Icons name='user' color={color} size={size[5]} /> }}
      />
    </BottomTab.Navigator>
  )
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: { name: React.ComponentProps<typeof FontAwesome>['name']; color: string }) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />
}
