import { Alert, FlatList, StyleSheet, View } from "react-native"
import { Button, FormText, LoadingLottie, Pagination, Text, colors, size } from "@starter"
import { FC, useEffect, useState } from "react"
import { productsActions, productsApiGet, productsSeletor } from "@slice/products"
import { reverse, uniqWith } from "ramda"
import { useDispatch, useSelector } from "react-redux"

import { Constants } from '../helper/constants'
import { IChartItem } from "@slice/orderTyps"
import { ProductModal } from "./ProductModal"
import { ProductRow } from "./ProductRow"

interface IProductsSelectionController {
    currentItems: IChartItem[]
    shopId: string
    supplierId: string
    onPressConfirm: (items: IChartItem[]) => void
}

export const ProductsSelectionController: FC<IProductsSelectionController> = ({ currentItems, shopId, supplierId, onPressConfirm }) => {
    const [chartItems, setChartItems] = useState<IChartItem[]>(currentItems)
    const [selectedItem, setSelectedItem] = useState<IChartItem | undefined>(undefined)
    const [search, setSearch] = useState<string>('')
    const { status, products, limit, currentPage } = useSelector(productsSeletor)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(productsApiGet({ shopId, supplierId }))
    }, [shopId, supplierId])

    useEffect(() => {
        if (status === 'success') {
            dispatch(productsActions.resetStatus())
        } else if (status === 'failed') {
            Alert.alert('下載最新產品資料失敗', undefined, [
                {
                    text: 'OK',
                    onPress: () => {
                        dispatch(productsActions.resetStatus())
                    },
                },
            ])
        }
    }, [status])

    const onPageChange = (page: number) => {
        dispatch(productsApiGet({ page: page, productName: search, shopId: shopId, supplierId: supplierId }))
    }

    const addProductToChart = (product: IChartItem) => {
        let newItems = reverse(
            uniqWith((lhs, rhs) => {
                return lhs.BC === rhs.BC
            }, reverse(chartItems.concat(product)))
        ).filter((item) => !!item.count)
        setChartItems(newItems)
    }

    const onPressNext = () => {
        onPressConfirm(chartItems)
    }

    return <>
        <View style={styles.searchContainer}>
            <FormText
                placeHolder='請輸入產品名稱...'
                containerStyle={styles.input}
                title={''}
                onChangeText={setSearch}
            />
            {/* <Button title='搜尋' onPress={onPressSearch} /> */}
        </View>
        {products.length === 0 && status !== 'loading' &&
            <View style={styles.noDataContainer}>
                <Text.H1 style={styles.noDataText}>沒有產品</Text.H1>
            </View>
        }
        {products.length !== 0 &&
            <>
                <FlatList
                    data={
                        products.filter(p => {
                            const key = search.toLowerCase()
                            const { BC, PN1L, PN2L, SN2L, name } = p
                            return BC.toLowerCase().includes(key)
                                || PN1L.toLowerCase().includes(key)
                                || PN2L.toLowerCase().includes(key)
                                || SN2L.toLowerCase().includes(key)
                                || name?.toLowerCase().includes(key)
                        })
                    }
                    renderItem={({ item }) => {
                        const { BC } = item
                        const count = chartItems.find((i) => i.BC === BC)?.count
                        const clickedItem = chartItems.find(c => c.BC === item.BC) || { ...item, count: 0, remark: null }
                        return (
                            <ProductRow
                                item={item}
                                shopId={shopId}
                                onPress={() => setSelectedItem(clickedItem)}
                                description={!!count ? String(count) : ''}
                                isSelected={chartItems.findIndex(c => c.BC === item.BC) !== -1}
                            />
                        )
                    }}
                    keyExtractor={(p) => p.BC}
                />
                {!!limit && (
                    <View style={styles.paginationContainer}>
                        <Pagination
                            totalPages={Math.ceil(limit / Constants.productPageSize)}
                            currentPage={currentPage || 1}
                            onPageChange={onPageChange}
                        />
                    </View>
                )}
                <Button title='下一步' onPress={onPressNext} style={styles.nextButton} />
            </>
        }
        <ProductModal
            shopId={shopId}
            item={selectedItem}
            hideModel={() => setSelectedItem(undefined)}
            addItem={addProductToChart}
        />
        <LoadingLottie isVisible={status === 'loading'} />
    </>
}

const styles = StyleSheet.create({
    noDataContainer: {
        borderTopWidth: 1,
        borderTopColor: colors.gray400,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    noDataText: {
        padding: size[4],
        textAlign: 'center',
        color: colors.gray400
    },
    container: {
        flex: 1,
        backgroundColor: colors.white,
    },
    paginationContainer: {
        alignItems: 'center',
    },
    nextButton: {
        padding: size[4],
    },
    searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: size[4],
    },
    input: {
        flex: 1,
        marginRight: size[4],
    },
})
