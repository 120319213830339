import { AccountType } from './login'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { firebaseHelper } from './../../helper/firebase'
import { IFirebaseOrder } from './orderTyps'
import { RootState } from '@slice'
import { StoreStatus } from '@starter'


export type OrdersState = {
  status: StoreStatus
  orders?: IFirebaseOrder[]
}

export const ordersApiGet = createAsyncThunk<IFirebaseOrder[], {date?: Date} >('orders/get', async ({date}, { getState }) => {
  const { login } = getState() as RootState
  const { supplierId, shopId, accountType } = login
  const documents =
    accountType === AccountType.ADMIN
      ? firebaseHelper.getOrders({queryObj: {}, date})
      : accountType === AccountType.SHOP_ADMIN
      ? firebaseHelper.getOrders({ queryObj: { shopId }, date })
      : firebaseHelper.getOrders({ queryObj: { supplierId }, date })
  const orders = (await documents).docs.map((doc) => {
    return { ...doc.data(), docId: doc.id }
  }) as IFirebaseOrder[]
  return orders
})

const initialState: OrdersState = {
  status: 'idle',
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ordersApiGet.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(ordersApiGet.fulfilled, (state, action) => {
        state.orders = action.payload
        state.status = 'success'
      })
      .addCase(ordersApiGet.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const ordersActions = ordersSlice.actions
export const ordersSeletor = (state: RootState) => state.orders
