import qs from 'qs'
import { api, StoreStatus, urlencodedHeader } from '@starter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { firebaseHelper } from './../../helper/firebase'
import { IChart, IErunCreateOrderResponse, IOrderInfo } from './orderTyps'
import { IFirebaseOrder } from '@slice/orderTyps'
import { ordersApiGet } from './orders'
import { RootState } from '@slice'
import { Timestamp } from 'firebase/firestore'


export type OrderState = {
  status: StoreStatus
  chart?: IChart
}

export const createOrderRequest = createAsyncThunk(
  'order/create',
  async (pendingOrder: IChart & IOrderInfo, { dispatch }) => {
    await firebaseHelper.insertNewOrder({
      ...pendingOrder,
      createDate: Timestamp.now(),
      status: 'pending',
    })
    dispatch(ordersApiGet({}))
  }
)

export const updateOrder = createAsyncThunk(
  'order/update',
  async ({ previous, current }: { previous: IFirebaseOrder; current: IFirebaseOrder }, { dispatch, getState }) => {
    const { login } = getState() as RootState
    const { sSUID, sSUPW } = login
    let erunOrder = {
      SN: current.shopId,
      SC: current.supplierId,
      OL: current.relatedBill,
      BD: current.billDate.toDate(),
      RE: current.remark,
      Items: current.items.map((item) => ({ BC: item.BC, QU: item.count, REM: item.remark })),
    } as any
    if (current.transactionId) {
      erunOrder['RL'] = current.transactionId
      erunOrder['BN'] = current.billNumber
    }
    const params = qs.stringify({ jTxn: JSON.stringify(erunOrder), sSUID, sSUPW })

    if (previous.status === 'pending' && current.status === 'accepted') {
      // From pending to accepted
      const { data } = await api().post<IErunCreateOrderResponse>('/Supplier_CreatePI_V2', params, {
        headers: urlencodedHeader,
      })
      await firebaseHelper.updateOrder({ ...current, transactionId: data?.RL || null, billNumber: data?.BN || null })
    } else if (previous.status === 'accepted' && current.status === 'completed') {
      // From accepted to completed
      await api().post('/Supplier_ModifyPI_V2', params, { headers: urlencodedHeader })
      await api().post('/Supplier_SetGoodsCountComplete_V2', { sRL: current.transactionId, sSUID, sSUPW })
      await firebaseHelper.updateOrder(current)
    } else if (previous.status === 'accepted' && current.status === 'accepted') {
      // Modify order only
      await api().post('/Supplier_ModifyPI_V2', params, { headers: urlencodedHeader })
      await firebaseHelper.updateOrder(current)
    } else {
      await firebaseHelper.updateOrder(current)
    }
    dispatch(ordersApiGet({}))
  }
)

export const orderApiRequest = createAsyncThunk('order/api/action', async () => { })

const initialState: OrderState = {
  status: 'idle',
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
    addChart: (state, { payload }: { payload: { chart: IChart } }) => {
      state.chart = payload.chart
    },
    clearChart: (state) => {
      state.chart = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderRequest.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createOrderRequest.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(createOrderRequest.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(updateOrder.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.status = 'success'
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const orderActions = orderSlice.actions
export const orderSeletor = (state: RootState) => state.order
