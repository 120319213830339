import { Button, FormDate, FormSelect, FormText, LoadingLottie, ModalWrapper, Row, SectionWrapper, Spacing, Text, colors, convertTimeString, size } from '@starter'
import { Controller, useForm } from 'react-hook-form'
import {
  IChartItem,
  IFirebaseOrder,
  IOrder,
  IOrderStatus
} from '@slice/orderTyps'
import { IOrderInfoForm, OrderReviewHook } from '../hooks/OrderReviewHook'
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native'
import { createOrderRequest, updateOrder } from '@slice/order'
import { useDispatch, useSelector } from 'react-redux'

import { LoggedInStackScreenProps } from '../navigation/types'
import { ProductModal } from '@components/ProductModal'
import { ProductsSelectionController } from '@components/ProductsSelectionController'
import { Timestamp } from 'firebase/firestore'
import { getShop } from '../helper/shopHelper'
import { getStatusTranslation } from '../helper/orderMapper'
import { shopSeletor } from '@slice/shop'
import { useState } from 'react'

export default function OrderReview({ navigation, route }: LoggedInStackScreenProps<'OrderReview'>) {
  const dispatch = useDispatch()
  const { shops } = useSelector(shopSeletor)
  const [shouldShowEditItem, setShouldShowEditItem] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IChartItem | undefined>(undefined)
  const { docId } = route.params
  const { control, handleSubmit, setValue } = useForm<IOrderInfoForm>({
    defaultValues: {
      relatedBill: null,
      billDate: new Date(),
      remark: null,
      status: 'pending',
    },
  })
  const { order, setOrder, isEditable, status, supplier } = OrderReviewHook({ docId: route.params.docId, setValue, navigation })
  const { nameC, nameE } = getShop(order?.shopId || '-1', shops)

  const onPressPlaceOrder = ({ relatedBill, billDate, remark }: IOrderInfoForm) => {
    if (order) {
      dispatch(createOrderRequest({ ...order, relatedBill, remark, billDate: Timestamp.fromDate(billDate) }))
    }
  }

  const onPressUpdateOrder = ({ relatedBill, billDate, remark, status }: IOrderInfoForm) => {
    if (!order) {
      return
    }
    const newOrder = {
      ...order,
      relatedBill,
      billDate: Timestamp.fromDate(billDate),
      remark,
      status,
    }
    dispatch(updateOrder({ previous: order as IFirebaseOrder, current: newOrder as IFirebaseOrder }))
  }

  const onChangeItem = (item: IChartItem) => {
    const items =
      order?.items.map((old) => {
        if (old.BC === item.BC) {
          return item
        } else {
          return old
        }
      }) || []
    const newOrder = { ...order, items } as IOrder
    setOrder(newOrder)
  }

  const onPressConfirmEditItem = (items: IChartItem[]) => {
    setShouldShowEditItem(false)
    const newOrder = { ...order, items } as IOrder
    setOrder(newOrder)
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.content}>
        <SectionWrapper style={styles.wrapper}>
          <Text.H2 style={styles.title}>店舖</Text.H2>
          <Row title='名稱(1)' description={nameC} />
          <Row title='名稱(2)' description={nameE} />
          {!!supplier && <Text.H2 style={styles.title}>{`供應商:${supplier.ID}`}</Text.H2>}
          {!!supplier && <Row title='名稱(1)' description={supplier.N1L} />}
          {!!supplier && <Row title='名稱(2)' description={supplier.N2L} />}
          <Row title='訂單編號' description={order?.orderId ?? '-'} />
          <Row title='狀態' description={getStatusTranslation(order?.status || '-')} />
        </SectionWrapper>
        <SectionWrapper style={styles.wrapper}>
          <Text.H2 style={styles.title}>訂單產品</Text.H2>
          {order?.items.map((item) => {
            let description = String(item.count)
            if (!!item.remark) {
              description += `\n${item.remark}`
            }
            return (
              <Row
                key={item.BC}
                title={`${item.PN1L}\n${item.SN2L}`}
                description={description}
                onPress={() => {
                  setSelectedItem(item)
                }}
              />
            )
          })}
          <Button type='neat' title='更改' onPress={() => setShouldShowEditItem(true)} disabled={!isEditable} />
        </SectionWrapper>
        <Spacing height={size[4]} />
        {!!docId && (
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, value } }) => {
              const statuses: IOrderStatus[] =
                order?.status === 'pending'
                  ? ['accepted']
                  : order?.status === 'accepted'
                    ? ['accepted', 'completed']
                    : []
              const options = statuses.map((s) => ({ title: getStatusTranslation(s), value: s }))
              return (
                <FormSelect
                  title='狀態'
                  text={getStatusTranslation(value)}
                  options={options}
                  onChangeOption={(option) => {
                    onChange(option.value)
                  }}
                  editable={isEditable}
                />
              )
            }}
          />
        )}
        <Spacing height={size[5]} />
        <Controller
          control={control}
          name='relatedBill'
          render={({ field: { onChange, value } }) => {
            return (
              <FormText
                title='相關單'
                text={value ?? ''}
                onChangeText={onChange}
                placeHolder='請輸入...'
                editable={isEditable}
              />
            )
          }}
        />
        <Spacing height={size[2]} />
        <Controller
          control={control}
          name='billDate'
          render={({ field: { onChange, value } }) => {
            return (
              <FormDate
                title='營業日期'
                mode='date'
                placeHolder='請輸入...'
                setTime={onChange}
                text={convertTimeString(value.toDateString(), 'YYYY-MM-DD')}
                editable={isEditable}
              />
            )
          }}
        />
        <Controller
          control={control}
          name='remark'
          render={({ field: { onChange, value } }) => {
            return (
              <FormText
                title='備註'
                text={value ?? ''}
                onChangeText={onChange}
                placeHolder='請輸入...'
                editable={isEditable}
              />
            )
          }}
        />
        <Spacing height={size[4]} />
        {!route.params.docId && <Button title='確認下單' onPress={handleSubmit(onPressPlaceOrder)} />}
        <Spacing height={size[4]} />
        {isEditable && !!route.params.docId && <Button title='更新訂單' onPress={handleSubmit(onPressUpdateOrder)} />}
        <Spacing height={size[4]} />
      </ScrollView>
      <ProductModal
        item={selectedItem}
        hideModel={() => setSelectedItem(undefined)}
        addItem={onChangeItem}
        shopId={order?.shopId ?? '-1'}
      />
      <LoadingLottie isVisible={status === 'loading'} />
      <ModalWrapper
        isVisible={shouldShowEditItem}
        title='更改產品'
        hideModel={() => setShouldShowEditItem(false)}
        enableBackgrounDismiss={false} >
        <ProductsSelectionController
          shopId={order?.shopId ?? ''}
          supplierId={order?.supplierId ?? ''}
          currentItems={order?.items ?? []}
          onPressConfirm={onPressConfirmEditItem} />
      </ModalWrapper>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: size[4],
    flex: 1,
    backgroundColor: colors.white,
  },
  title: {
    padding: size[4],
  },
  wrapper: {
    marginVertical: size[2],
  },
})
