import { StoreStatus, api } from '@starter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from './index'
import { firebaseHelper } from './../../helper/firebase'

export enum AccountType {
  ADMIN = 'admin',
  SUPPLIER = 'SUPPLIER',
  SHOP_ADMIN = 'SHOP_ADMIN',
}

interface ILoginResponse {
  SessionID: string
  /* 0 is failed; 1 is success */
  Status: 1 | 0
  Message: string
}

export type LoginState = {
  status: StoreStatus
  error?: any
  presetId?: string
  presetPassword?: string
  sessionID?: string
  isLoggedIn: boolean
  id?: string
  accountType: AccountType
  shopId: string
  supplierId?: string
  sSUID: string
  sSUPW: string
}

export const loginApiPost = createAsyncThunk<{
  sessionID: string; id: string, sSUID: string
  sSUPW: string
}, { id: string; password: string }>(
  'login/api/get',
  async (props, { rejectWithValue, dispatch }) => {
    const { id, password } = props
    dispatch(loginActions.presetAccount({ id, password }))

    if (id.includes('shop')) {
      const docs = await firebaseHelper.getUsers({ id, password, status: 'active' })
      if (docs.docs.length === 1) {
        // Firebase has this shop user then login admin
        const response = await api().get<ILoginResponse>('/Supplier_Login_J', {
          sLoginID: '999',
          sPassword: 'admin@999',
        })
        if (response && response.data?.Status === 1) {
          return { sessionID: response.data.SessionID, id, sSUID: '999', sSUPW: 'admin@999' }
        } else {
          throw rejectWithValue({})
        }
      } else {
        throw rejectWithValue({})
      }
    } else {
      const response = await api().get<ILoginResponse>('/Supplier_Login_J', { sLoginID: id, sPassword: password })
      console.log(response)
      if (response && response.data?.Status === 1) {
        return { sessionID: response.data.SessionID, id, sSUID: id, sSUPW: password }
      } else {
        throw rejectWithValue({})
      }
    }
  }
)

const initialState: LoginState = {
  status: 'idle',
  isLoggedIn: false,
  sessionID: undefined,
  id: undefined,
  accountType: AccountType.SUPPLIER,
  shopId: '-1',
  sSUID: '',
  sSUPW: '',
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
    logout: (state) => {
      return { ...state, ...initialState }
    },
    presetAccount: (state, { payload }: { payload: { id: string; password: string } }) => {
      state.presetId = payload.id
      state.presetPassword = payload.password
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginApiPost.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(loginApiPost.fulfilled, (state, action) => {
        state.status = 'success'
        state.sSUID = action.payload.sSUID
        state.sSUPW = action.payload.sSUPW
        state.isLoggedIn = true
        state.sessionID = action.payload.sessionID
        state.id = action.payload.id

        if (action.payload.id === '999') {
          state.accountType = AccountType.ADMIN
          state.shopId = '-1'
        } else if (action.payload.id.includes('shop')) {
          state.accountType = AccountType.SHOP_ADMIN
          state.shopId = action.payload.id.split('_')[1]
        } else {
          state.accountType = AccountType.SUPPLIER
          state.shopId = '-1'
          state.supplierId = action.payload.id
        }
      })
      .addCase(loginApiPost.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const loginActions = loginSlice.actions
export const loginSeletor = (state: RootState) => state.login
