import moment from 'moment'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where
  } from 'firebase/firestore'
import { dissoc } from 'ramda'
import { getApps, initializeApp } from 'firebase/app'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { IFirebaseOrder, IOrder } from '../reducers/slice/orderTyps'
import { IRefillProduct } from '../reducers/slice/productsType'


export type IUserStatus = 'active' | 'declined'

export interface IUser {
  id: string
  password: string
  status: IUserStatus
}

export interface IFirebaseUser extends IUser {
  docId: string
}

const firebaseConfig = {
  apiKey: 'AIzaSyCVlQG1a_zeM9BisEgIzM8LbIxcreXvTFc',
  authDomain: 'mankee-25d7b.firebaseapp.com',
  projectId: 'mankee-25d7b',
  storageBucket: 'mankee-25d7b.appspot.com',
  messagingSenderId: '47356240537',
  appId: '1:47356240537:web:e9e70a9e4d9dcd868b0e08',
  measurementId: 'G-YF50QKH3B3',
}

const initFirebase = () => {
  if (getApps().length === 0) {
    initializeApp(firebaseConfig)
  }
}

const insertNewOrder = (order: IOrder) => {
  const store = getFirestore()
  return addDoc(collection(store, 'orders'), order)
}

const updateOrder = (order: IFirebaseOrder) => {
  const id = order.docId
  const newOrder = dissoc('docId', order)
  const store = getFirestore()
  const ref = doc(store, 'orders', id)
  return updateDoc(ref, newOrder)
}

const getOrders = ({queryObj, date = new Date()}: {queryObj: any, date?: Date}) => {
  const store = getFirestore()
  const ref = collection(store, 'orders')
  const constraints = Object.keys(queryObj).map((key) => {
    return where(key, '==', queryObj[key])
  })
  const startingDate = moment(date).startOf('month').toDate()
  const endDate = moment(date).add(1, 'month').startOf('month').toDate()
  const q = query(ref, orderBy('createDate', 'desc'), limit(250), ...constraints, where('createDate', '>=', startingDate), where('createDate', '<', endDate))
  return getDocs(q)
}

const getUsers = (queryObj: any) => {
  const store = getFirestore()
  const ref = collection(store, 'users')
  const constraints = Object.keys(queryObj).map((key) => {
    return where(key, '==', queryObj[key])
  })
  const q = query(ref, ...constraints)
  return getDocs(q)
}

const addUser = async (user: IUser) => {
  const store = getFirestore()
  const ref = collection(store, 'users')
  const q = query(ref, where('id', '==',user.id))
  const existing = await getDocs(q)
  if (existing.docs.length !== 0) {
    throw 'This ID is already existed'
  }
  return addDoc(collection(store, 'users'), user)
}

const updateUser = (user: IFirebaseUser) => {
  const id = user.docId
  const newUser = dissoc('docId', user)
  const store = getFirestore()
  const ref = doc(store, 'users', id)
  console.log(user, newUser)
  return updateDoc(ref, newUser)
}

export interface IRefillSetting {
  [shopId: string]: {
    [productId: string]: IRefillProduct
  }
}

const getProductsRefillSetting = async (shopId?: string) => {
  const store = getFirestore()
  const ref = collection(store, 'product_setting')
  const docsSnapshot = await getDocs(ref)
  return docsSnapshot.docs.reduce((pre, cur) => {
    if (shopId && cur.id !== shopId) {
      return pre
    }
    return {
      ...pre,
      [cur.id]: cur.data(),
    }
  }, {}) as IRefillSetting
}

const uploadSafeAmountFile = (file: File) => {
  const today = new Date()
  const fileName = `${today.getTime()}.xls`
  const storage = getStorage()
  const storageRef = ref(storage, `safe_amount_excel/${fileName}`)
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log('uploaded')
  })
}

export interface IProductSettingStatuses {
  id: string,
  isSuccess: boolean,
  message: string,
  timestamp: Timestamp
}

const getProductSettingStatuses =async () => {
  const store = getFirestore()
  const ref = collection(store, 'product_setting_statuses')
  const docsSnapshot = await getDocs(query(ref, orderBy('timestamp', 'desc')))
  return docsSnapshot.docs.map((v) => {
    return {
      id: v.id,
      ...v.data()
    }
  }) as IProductSettingStatuses[]
}

export const firebaseHelper = {
  initFirebase,
  insertNewOrder,
  updateOrder,
  getOrders,
  getUsers,
  addUser,
  updateUser,
  uploadSafeAmountFile,
  getProductsRefillSetting,
  getProductSettingStatuses
}
