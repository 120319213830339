import moment from 'moment'
import {
  Alert,
  FlatList,
  Image,
  RefreshControl,
  StyleSheet,
  View
  } from 'react-native'
import { colors } from '@starter/themes/colors'
import { convertTimeString } from '@starter/helper/timeHelper'
import { getStatusTranslation } from '../helper/orderMapper'
import { Icons, Pagination } from '@starter'
import { IFirebaseOrder } from '@slice/orderTyps'
import { images } from '@starter/themes/images'
import { LoggedInBottomTabProps } from '../navigation/types'
import { ordersActions, ordersApiGet, ordersSeletor } from '@slice/orders'
import { Row } from '@starter/component/Row'
import { SectionWrapper } from '@starter/component/Wrapper/SectionWrapper'
import { size } from '@starter/themes/size'
import { supplierSeletor } from '@slice/supplier'
import { Text } from '@starter/component/Text'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useShops } from '../hooks/useShops'


export default function OrdersScreen({ navigation }: LoggedInBottomTabProps<'Orders'>) {
  const dispatch = useDispatch()
  const [date, setDate] = useState<Date>(new Date())
  const { orders, status } = useSelector(ordersSeletor)
  const { suppliers } = useSelector(supplierSeletor)
  useShops()

  useEffect(() => {
    if (status === 'failed') {
      Alert.alert('下載訂單失敗', '請檢查網絡，然後再試', [
        {
          text: 'OK',
          onPress: () => {
            dispatch(ordersActions.resetStatus())
          },
        },
      ])
    } else if (status === 'success') {
      dispatch(ordersActions.resetStatus())
    }
  }, [status])

  useEffect(() => {
    getOrders()
  }, [date])

  const getOrders = () => {
    dispatch(ordersApiGet({ date }))
  }

  const onSelectOrder = (item: IFirebaseOrder) => {
    navigation.navigate('OrderReview', { docId: item.docId })
  }

  const onPreePreviouse = () => {
    const newDate = moment(date).subtract(1, 'month').toDate()
    setDate(newDate)
  }

  const onPressNext = () => {
    const newDate = moment(date).add(1, 'month').toDate()
    if (moment(newDate).isBefore(new Date())) {
      setDate(newDate)
    }
  }

  const renderMonthSelection = () => {
    const nextMonth = moment(date).add(1, 'month')
    const isThisMonth = nextMonth.isAfter(new Date())
    return <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <Icons name='left' onPress={onPreePreviouse} size={38} />
      <Text.H1 style={{paddingHorizontal: size[4]}}>{moment(date).format('yyyy-MM')}</Text.H1>
      <Icons
        color={isThisMonth ? colors.gray400 : colors.dark}
        name='right'
        onPress={onPressNext} size={38}
      />
    </View>
  }

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image source={images.icon} style={styles.image} resizeMode='contain' />
      </View>
      {renderMonthSelection()}
      {!!orders && (
        <FlatList
          data={orders}
          renderItem={({ item }) => {
            const createDate = new Date(item.createDate.seconds * 1000)
            const supplier = suppliers.find((s) => item.supplierId === s.ID)
            return (
              <SectionWrapper style={styles.section} onPress={() => onSelectOrder(item)}>
                <Row title='下單日期' description={convertTimeString(createDate.toDateString(), 'YYYY-MM-DD')} />
                <Row
                  title='供應商'
                  description={supplier?.N1L ? `${supplier.N1L}(${supplier?.ID})` : item.supplierId}
                />
                <Row title='分店名稱' description={item.orderId || '-'} />
                <Row title='狀態' description={getStatusTranslation(item.status)} />
              </SectionWrapper>
            )
          }}
          keyExtractor={(data) => data.docId}
          refreshControl={<RefreshControl refreshing={status === 'loading'} onRefresh={getOrders} />}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section: {
    margin: size[2],
  },
  imageContainer: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    opacity: 0.3,
  },
  image: {
    width: (size.screenWidth / 3) * 2,
    height: size.screenHeight / 2,
  },
})
