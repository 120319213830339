import {
  Alert,
  FlatList,
  StyleSheet,
  View
  } from 'react-native'
import { colors } from '@starter/themes/colors'
import { FormText } from '@starter/component/Form/FormText'
import {
  ISupplier,
  supplierActions,
  supplierListApiGet,
  supplierSeletor
  } from '@slice/supplier'
import { LoadingLottie } from '@starter/component/LoadingLottie'
import { LoggedInBottomTabProps } from '../navigation/types'
import { productsActions } from '@slice/products'
import { Row } from '@starter/component/Row'
import { size } from '@starter/themes/size'
import { Text } from '@starter/component/Text'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'


export default function SuppliersScreen({ navigation }: LoggedInBottomTabProps<'Suppliers'>) {
  const { status, suppliers } = useSelector(supplierSeletor)
  const [displaySuppliers, setDisplaySuppliers] = useState<ISupplier[]>([])
  const [filter, setFilter] = useState<string>('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(supplierListApiGet())
  }, [])

  useEffect(() => {
    const f = filter.toLowerCase()
    setDisplaySuppliers(suppliers.filter(s =>
      s.ID.toLowerCase().includes(f) ||
      s.N1L.toLowerCase().includes(f) ||
      s.N2L.toLowerCase().includes(f)))
  }, [suppliers, filter])

  useEffect(() => {
    if (status === 'success') {
      dispatch(supplierActions.resetStatus())
    } else if (status === 'failed') {
      Alert.alert('下載供應商失敗', undefined, [
        {
          text: 'OK',
          onPress: () => {
            dispatch(productsActions.resetStatus())
          },
        },
      ])
    }
  }, [status])

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <FormText
          placeHolder='請輸入搜尋資料...'
          containerStyle={styles.input}
          title={''}
          onChangeText={setFilter}
        />
      </View>
      <FlatList
        data={displaySuppliers}
        renderItem={({ item }) => {
          return <Row title={item.N1L} description={item.ID} otherContent={<Text.Caption>{item.N2L}</Text.Caption>} />
        }}
        keyExtractor={(p) => p.ID}
      />
      <LoadingLottie isVisible={status === 'loading'} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  paginationContainer: {
    alignItems: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: size[4],
  },
  input: {
    flex: 1,
    marginRight: size[4],
  },
})
