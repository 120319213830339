import { FC, useEffect, useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'

import { Button } from '@starter/component/Button'
import { FormText } from '@starter/component/Form/FormText'
import { IChartItem } from '@slice/orderTyps'
import { ModalWrapper } from '@starter/component/Wrapper/ModalWrapper'
import { Spacing } from '@starter/component/Spacing'
import { Text } from '@starter/component/Text'
import { size } from '@starter/themes/size'

interface IProductModalProps {
  item?: IChartItem
  hideModel: () => void
  addItem: (item: IChartItem) => void
  shopId: string
}

export const ProductModal: FC<IProductModalProps> = ({ item, addItem, hideModel, shopId }) => {
  const [count, setCount] = useState('')
  const [remark, setRemark] = useState<string | null>(null)

  useEffect(() => {
    if (item) {
      setCount(item.count === 0 ? '' : item.count.toString())
      setRemark(item.remark)
    } else {
      setCount('')
      setRemark(null)
    }
  }, [item])

  const onPressConfirm = () => {
    hideModel()
    if (item && !isNaN(Number(count))) {
      addItem({ ...item, count: Number(count), remark: remark || null })
    } else if (item) {
      addItem({ ...item, count: 0, remark: remark || null })
    }
  }

  if (!item) {
    return null
  }

  const { ImageURL, BC, PN1L, PN2L, SN2L, StockQty, SalesQty, safe, orderAmount } = item
  const imageUrl = `https://mankee.softether.net/erunapitest/${ImageURL?.replaceAll('\\', '/')}`
  const refillInfo = safe && orderAmount ? `安全量: ${safe} | 重訂量: ${orderAmount}` : undefined
  const other = `庫存量: ${StockQty} | 銷售量: ${SalesQty}`

  return (
    <ModalWrapper title='產品資料' hideModel={hideModel} isVisible={!!item} enableBackgrounDismiss={false}>
      <View style={styles.container}>
        {!!ImageURL && <Image style={styles.image} resizeMode='contain' source={{ uri: imageUrl }} />}
        <Spacing height={size[2]} />
        <Text>{`編碼: ${BC}`}</Text>
        <Text>{`名稱: ${PN1L}`}</Text>
        <Text>{`名稱2: ${PN2L}`}</Text>
        <Text>{`供應商參考編號: ${SN2L}`}</Text>
        <Text>{other}</Text>
        {!!refillInfo && <Text>{refillInfo}</Text>}
        {!!PN2L && PN2L !== PN1L && <Text>{`名稱2: ${PN2L}`}</Text>}
        <Spacing height={size[4]} />
        <FormText title='下單數量' onChangeText={setCount} keyboardType='number-pad' text={count} />
        <FormText title='備註' onChangeText={setRemark} text={remark || ''} />
        <Spacing height={size[2]} />
        <Button title='確定' onPress={onPressConfirm} />
        <Spacing height={size[2]} />
      </View>
    </ModalWrapper>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: size[2],
  },
  image: {
    height: 150,
  },
})
