import { AccountType, loginSeletor } from '@slice/login'
import { Alert } from 'react-native'
import { IOrder, IOrderStatus } from './../reducers/slice/orderTyps'
import { ISupplier } from './../reducers/slice/supplier'
import { LoggedInStackParamList } from '../navigation/types'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { orderActions, orderSeletor } from '@slice/order'
import { ordersApiGet, ordersSeletor } from '@slice/orders'
import { supplierSeletor } from '@slice/supplier'
import { Timestamp } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'


export interface IOrderInfoForm {
  relatedBill: string | null
  billDate: Date
  remark: string | null
  status: IOrderStatus
}

interface IOrderReviewHook {
  docId?: string
  setValue: UseFormSetValue<IOrderInfoForm>
  navigation: NativeStackNavigationProp<LoggedInStackParamList, 'OrderReview', undefined>
}

export const OrderReviewHook = ({ docId, setValue, navigation }: IOrderReviewHook) => {
  const [order, setOrder] = useState<IOrder | undefined>(undefined)
  const [supplier, setSupplier] = useState<ISupplier | undefined>(undefined)
  const { suppliers } = useSelector(supplierSeletor)
  const dispatch = useDispatch()
  const { chart } = useSelector(orderSeletor)
  const { orders } = useSelector(ordersSeletor)
  const { accountType } = useSelector(loginSeletor)
  const isEditable =
    ((accountType === AccountType.ADMIN || accountType === AccountType.SHOP_ADMIN) &&
      !!docId &&
      order?.status !== 'completed') ||
    !docId
  const { status } = useSelector(orderSeletor)

  useEffect(() => {
    if (status === 'success') {
      dispatch(orderActions.clearChart())
      dispatch(orderActions.resetStatus())
      navigation.navigate('Root')
    } else if (status === 'failed') {
      Alert.alert('提交訂單失敗', '請稍候再試', [
        {
          text: 'ok',
          onPress: () => {
            dispatch(orderActions.resetStatus())
          },
        },
      ])
    }
  }, [status])

  useEffect(() => {
    if (docId) {
      const doc = orders?.find((o) => o.docId === docId)
      doc?.relatedBill && setValue('relatedBill', doc.relatedBill)
      doc?.billDate && setValue('billDate', new Date(doc.billDate.seconds * 1000))
      doc?.remark && setValue('remark', doc.remark)
      doc?.status && setValue('status', doc.status)
      const sup =  suppliers.find(s => s.ID === doc?.supplierId) 
      setSupplier(sup)
      setOrder(doc)
    } else if (chart) {
      setOrder({
        ...chart,
        remark: null,
        billDate: Timestamp.now(),
        relatedBill: null,
        createDate: Timestamp.now(),
        status: '-',
      })
      const sup =  suppliers.find(s => s.ID === chart.supplierId) 
      setSupplier(sup)
    }
  }, [])
  return { order, setOrder, isEditable, status, supplier }
}
