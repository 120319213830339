import { IProducctsCountGetResponse, IProduct, IProductsGetResponse } from './productsType'
import { StoreStatus, api } from '@starter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Constants } from './../../helper/constants'
import { RootState } from '@slice'
import { firebaseHelper } from './../../helper/firebase'

export type ProductsState = {
  status: StoreStatus
  products: IProduct[]
  limit: number
  currentPage: number
}

export const productsApiGet = createAsyncThunk<
  { products: IProduct[]; productsCount: number; currentPage: number },
  { page?: number; productName?: string; shopId?: string, supplierId?: string }
>('products/api/get', async ({ page = 1, productName = '', shopId = '-1', supplierId }, { rejectWithValue, dispatch, getState }) => {
  const { login } = getState() as RootState
  const {sSUID, sSUPW} = login
  const productsResponse = await api().post<IProductsGetResponse>('/Product_GetStockByShopForSupplierV2_J', {
    iSN: shopId,
    sSU: supplierId,
    bExclZeroSales: true,
    sOrderBy: 'BC',
    iRecFrom: Constants.productPageSize * (page - 1),
    iRecTo: Constants.productPageSize * page,
    bGetImagePath: true,
    sPNxL: productName,
    sSUID,
    sSUPW,
    bAscend: true,
  })
  const pageResponse = await api().post<IProducctsCountGetResponse>('/Product_GetStockByShopForSupplierCountV2_J', {
    iSN: shopId,
    sSU: supplierId,
    bExclZeroSales: true,
    sPNxL: productName,
    sSUID,
    sSUPW
  })
  if (productsResponse && productsResponse.data?.Status === 1) {
    const refillSetting = (await firebaseHelper.getProductsRefillSetting(shopId))?.[shopId]
    const products = productsResponse.data?.ProductStockSales.map((p) => {
      return {
        ...p,
        ...refillSetting?.[p.UBC],
      }
    }).sort(({ safe: lSafe, StockQty: lStockQty }, { safe: rSafe, StockQty: rStockQty }) => {
      const lDiff = lSafe ? (lStockQty || 0) - lSafe : 0
      const rDiff = rSafe ? (rStockQty || 0) - rSafe : 0

      return lDiff - rDiff
    })
    return {
      products: products || [],
      productsCount: pageResponse.data?.Count || 0,
      currentPage: page,
    }
  } else {
    return {
      products: [],
      productsCount: 0,
      currentPage: 0,
      limit: 0
    }
  }
})

const initialState: ProductsState = {
  status: 'idle',
  products: [],
  limit: 0,
  currentPage: 1,
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
    resetLogin: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productsApiGet.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(productsApiGet.fulfilled, (state, action) => {
        state.status = 'success'
        state.products = action.payload.products
        state.limit = action.payload.productsCount
        state.currentPage = action.payload.currentPage
      })
      .addCase(productsApiGet.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const productsActions = productsSlice.actions
export const productsSeletor = (state: RootState) => state.products
