import * as DocumentPicker from 'expo-document-picker';
import { AccountType, loginActions, loginSeletor } from '@slice/login';
import {
  Alert,
  FlatList,
  Image,
  StyleSheet,
  View
  } from 'react-native';
import { Button } from '@starter/component/Button';
import { colors } from '@starter/themes/colors';
import { convertTimeString } from '@starter';
import { firebaseHelper, IProductSettingStatuses } from '../helper/firebase';
import { images } from '@starter/themes/images';
import { LoggedInBottomTabProps, LoggedInStackParamList, LoggedInStackScreenProps } from '../navigation/types';
import { Row } from '@starter/component/Row';
import { size } from '@starter/themes/size';
import { Spacing } from '@starter/component/Spacing';
import { supplierSeletor } from '@slice/supplier';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';



export default function SafeRecordUploadStatusesScreen({ navigation }: LoggedInStackScreenProps<'SafeRecordUploadStatuses'>) {
  const [statuses, setStatuses] = useState<IProductSettingStatuses[]>([])

  useEffect(() => {
    firebaseHelper.getProductSettingStatuses().then(a => {
      console.log(a)
      setStatuses(a)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  return (
    <View>
      <FlatList data={statuses} keyExtractor={item => item.id} renderItem={({ item }) => {
        const date = new Date(item.timestamp.seconds * 1000)
        const dateStr = convertTimeString(date.toDateString(), 'YYYY-MM-DD')
        return <Row leftIcon={<View style={[styles.dot, {backgroundColor: item.isSuccess ? 'green' : colors.danger}]}></View>} title={item.message} description={dateStr} />
      }} />
    </View>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: size[2], height: size[2], backgroundColor: colors.danger, borderRadius: size[1]
  }
})
