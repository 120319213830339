import { AccountType, loginActions } from '@slice/login'
import { StoreStatus, api } from '@starter'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from '@slice'

export interface ISupplier {
  ID: string
  N1L: string
  N2L: string
}

interface ISupplierListResponse {
  Message: string
  Status: 0 | 1
  Suppliers: ISupplier[]
}

export type SupplierState = {
  status: StoreStatus
  suppliers: ISupplier[]
}

export const supplierListApiGet = createAsyncThunk(
  'supplier/api/list/get',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState
    const { sSUID, sSUPW } = state.login
    const response = await api().post<ISupplierListResponse>('/Product_GetSupplierListV1_J', { sSUID, sSUPW })
    if (response && response.data?.Status === 1) {
      const suppliers = response.data.Suppliers.filter(s => state.login.accountType !== AccountType.SUPPLIER || state.login.supplierId === s.ID)
      return suppliers
    } else {
      dispatch(loginActions.logout())
      throw rejectWithValue({})
    }
  }
)

const initialState: SupplierState = {
  status: 'idle',
  suppliers: [],
}

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
    resetLogin: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(supplierListApiGet.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(supplierListApiGet.fulfilled, (state, action) => {
        state.status = 'success'
        state.suppliers = action.payload
      })
      .addCase(supplierListApiGet.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const supplierActions = supplierSlice.actions
export const supplierSeletor = (state: RootState) => state.supplier
