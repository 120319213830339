export const colors = {
  primary: '#690000',
  primaryLight: '#c90000',
  primaryDark: '#3d0000',
  secondary: '#006a6f',
  secondaryLight: '#00b4bc',
  secondartDark: '#002f31',
  gray100: '#F5F5F5',
  gray200: '#EEEEEE',
  gray400: '#BDBDBD',
  gray600: '#757575',
  gray800: '#424242',
  dark: '#000000',
  white: '#FFFFFF',
  danger: '#B00020',
  secondaryDanger: '#ffcdd2',
}
