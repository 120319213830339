import { Button } from '@starter/component/Button'
import { colors } from '@starter/themes/colors'
import { firebaseHelper, IFirebaseUser } from '../helper/firebase'
import { FlatList, StyleSheet, View } from 'react-native'
import { LoggedInStackScreenProps } from '../navigation/types'
import { Row } from '@starter/component/Row'
import { size } from '@starter/themes/size'
import { useEffect, useState } from 'react'
import { UserModal } from '@components/UserModal'


export default function UsersScreen({ navigation }: LoggedInStackScreenProps<'Users'>) {
  const [users, setUsers] = useState<IFirebaseUser[]>([])
  const [selectedUser, setSelectedUser] = useState<IFirebaseUser | undefined>(undefined)
  const [shouldShowModal, setShouldShowModal] = useState(false)

  useEffect(() => {
    updateUserList()
  }, [])

  const updateUserList = () => {
    firebaseHelper.getUsers({}).then((result) => {
      const users = result.docs.map((value) => ({
        ...value.data(),
        docId: value.id
      })) as IFirebaseUser[]
      setUsers(users)
    }).catch(e => console.log(e))
  }

  const onHideModal = () => {
    setShouldShowModal(false)
    setSelectedUser(undefined)
    updateUserList()
  }

  const onPressNew = () => {
    setShouldShowModal(true)
  }

  const onPressRow = (user: IFirebaseUser) => {
    setSelectedUser(user)
    setShouldShowModal(true)
  }

  return (
    <View style={styles.container}>
      <FlatList data={users} renderItem={({ item }) => {
        return <Row title={item.id} description={item.status} onPress={() => onPressRow(item)} />
      }} />
      <Button style={styles.buttonContainer} title='新增帳戶' onPress={onPressNew} />
      <UserModal isVisible={shouldShowModal} hideModel={onHideModal} user={selectedUser} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    padding: size[4],
    borderTopWidth: 1,
    borderColor: colors.gray800,
  },
})
