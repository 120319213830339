import { StyleSheet, View } from 'react-native'

import { FC } from 'react'
import { IProduct } from '@slice/productsType'
import { Row } from '@starter/component/Row'
import { Text } from '@starter/component/Text'
import { colors } from '@starter/themes/colors'
import { convertTimeString } from '@starter/helper/timeHelper'

interface IProductRow {
  item: IProduct
  shopId: string
  description?: string
  onPress?: () => void
  isSelected: boolean
}

export const ProductRow: FC<IProductRow> = ({ item, shopId, onPress, isSelected }) => {
  const { PN1L, StockQty, SalesQty, SalesStartDate, safe, orderAmount, BC, SN2L } = item
  const description = SalesStartDate ? `開始銷售日期: ${convertTimeString(SalesStartDate, 'YYYY-MM-DD')}` : null
  const other = `庫存量: ${StockQty} | 銷售量: ${SalesQty}`
  const refillInfo = safe && orderAmount ? `安全量: ${safe} | 重訂量: ${orderAmount}` : undefined
  const captionStyle = StockQty === 0 ? { color: colors.danger } : undefined

  const containerStyle = isSelected ? {backgroundColor: colors.gray100 } :
    safe && StockQty < safe ? { backgroundColor: colors.secondaryDanger } : undefined
  return (
    <Row
      title={PN1L}
      otherContent={
        <View style={styles.contentContainer}>
          <View style={styles.contentLeft}>
            <Text.Caption>{description}</Text.Caption>
            <Text.Caption style={captionStyle}>{other}</Text.Caption>
            {!!refillInfo && <Text.Caption style={captionStyle}>{refillInfo}</Text.Caption>}
          </View>
          <View style={styles.contentRight}>
            <Text.Caption style={styles.description}>{`編碼: ${BC}\n供應商參考編號: ${SN2L}`}</Text.Caption>
          </View>
        </View>
      }

      onPress={onPress}
      containerStyle={containerStyle}
    />
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row'
  },
  contentLeft: {
    flex: 1
  },
  contentRight: {

  },
  description: {
    color: colors.gray800,
    textAlign: 'right',
  },
})
