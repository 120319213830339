import { IRefillSetting, firebaseHelper } from './../../helper/firebase'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from '@slice'
import { StoreStatus } from '@starter'

export type RefillState = {
  status: StoreStatus
  refillSetting?: IRefillSetting
}

export const refillApiGet = createAsyncThunk('refill/api/action', async () => {
  const products = await firebaseHelper.getProductsRefillSetting()
  return products
})

const initialState: RefillState = {
  status: 'idle',
}

export const refillSlice = createSlice({
  name: 'refill',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle'
    },
    resetLogin: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refillApiGet.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(refillApiGet.fulfilled, (state, action) => {
        state.status = 'success'
        state.refillSetting = action.payload
      })
      .addCase(refillApiGet.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export const refillActions = refillSlice.actions
export const refillSeletor = (state: RootState) => state.refill
