import * as Linking from 'expo-linking';
import { LinkingOptions } from '@react-navigation/native';
import { RootStackParamList } from './types';


/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */



const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Login: 'login',
      LoggedIn: {
        screens: {
          Root: {
            screens: {
              TabOne: {
                screens: {
                  TabOneScreen: 'one',
                },
              },
              TabTwo: {
                screens: {
                  TabTwoScreen: 'two',
                },
              },
              Profile: {
                screens: {
                  ProfileScreen: 'profile',
                },
              },
              Suppliers: {
                screens: {
                  SuppliersScreen: 'suppliers',
                },
              },
              Orders: {
                screens: {
                  OrdersScreen: 'orders',
                },
              },
              Shops: {
                screens: {
                  ShopsScreen: 'shops',
                },
              },
            },
          },
          SelectShop: 'select_shop',
          SelectProduct: 'select_product',
          SelectSupplier: 'select_supplier',
          OrderReview: {
            path: 'order',
          },
          Users: 'users',
          SafeRecordUploadStatuses: 'safe_record_upload_statuses'
        },
      },
      Modal: 'modal',
      NotFound: '*',
    },
  },
}

export default linking
