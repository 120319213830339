import { useEffect } from 'react'
import { shopSeletor, shopApiGet } from '@slice/shop'
import { useDispatch, useSelector } from 'react-redux'

export const useShops = () => {
  const dispatch = useDispatch()
  const { shops } = useSelector(shopSeletor)

  useEffect(() => {
    dispatch(shopApiGet())
  }, [])
  return { shops }
}
