import { IOrderStatus } from './../redux/slice/orderTyps'

export const getStatusTranslation = (status: IOrderStatus) => {
  switch (status) {
    case 'pending':
      return '處理中'
    case 'accepted':
      return '已接受'
    case 'completed':
      return '完成點貨'
    default:
      return status
  }
}
