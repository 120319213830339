import {
  Alert,
  Image,
  StyleSheet,
  View
  } from 'react-native';
import { Button } from '@starter/component/Button';
import { colors } from '@starter/themes/colors';
import { Controller, useForm } from 'react-hook-form';
import { FormText } from '@starter/component/Form/FormText';
import { images } from '@starter/themes/images';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { LoadingLottie } from '@starter/component/LoadingLottie';
import { loginActions, loginApiPost, loginSeletor } from '@slice/login';
import { RootStackScreenProps } from '../navigation/types';
import { size } from '@starter/themes/size';
import { Spacing } from '@starter/component/Spacing';
import { Text } from '@starter/component/Text';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';


export default function LoginScreen({ navigation }: RootStackScreenProps<'Login'>) {
  const { presetId, presetPassword, status } = useSelector(loginSeletor)
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === 'failed') {
      Alert.alert('登入失敗', '請檢查輸入資料然後再試。', [
        {
          text: 'ok',
          onPress: () => {
            dispatch(loginActions.resetStatus())
          },
        },
      ])
    } else if (status === 'success') {
      dispatch(loginActions.resetStatus())
    }
  }, [status])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: presetId || '',
      password: presetPassword || '',
    },
  })

  const onPressLogin = (value: { id: string; password: string }) => {
    dispatch(loginApiPost(value))
  }

  return (
    <KeyboardAwareScrollView style={{ backgroundColor: colors.white }} enableOnAndroid={true}>
      <View style={styles.container}>
        <Image source={images.icon} style={styles.image} resizeMode='contain' />
        {/* <Text.Caption>{'Demo account(supplier): 16 / 999'}</Text.Caption>
        <Text.Caption>{'Demo account(shop_admin): shop_1_01 / 999'}</Text.Caption>
        <Text.Caption>{'Demo account(admin): 999 / admin@999'}</Text.Caption> */}
        <Spacing height={size[4]} />
        <Controller
          control={control}
          name='id'
          render={({ field: { onChange, value } }) => {
            return (
              <FormText
                text={value}
                title='用戶編號'
                placeHolder='請輸入...'
                onChangeText={onChange}
                error={errors.id ? '必須填寫' : ''}
              />
            )
          }}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name='password'
          render={({ field: { onChange, value } }) => {
            return (
              <FormText
                text={value}
                title='密碼'
                isPaasword
                placeHolder='請輸入...'
                onChangeText={onChange}
                error={errors.password ? '必須填寫' : ''}
              />
            )
          }}
          rules={{ required: true }}
        />
        <Spacing height={size[4]} />
        <Button title='登入' onPress={handleSubmit(onPressLogin)} />
        <LoadingLottie isVisible={status === 'loading'} />
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: size[4],
  },
  image: {
    width: 460,
    height: 460,
    alignSelf: 'center',
    margin: size[5],
  },
})
