import { refillSlice, RefillState } from './refill'
import { ordersSlice, OrdersState } from './orders'
import { productsSlice, ProductsState } from './products'
import { supplierSlice, SupplierState } from './supplier'
import { loginSlice, LoginState } from './login'
import { orderSlice, OrderState } from './order'
import { shopSlice, ShopState } from './shop'

export const Reducers = {
  [loginSlice.name]: loginSlice.reducer,
  [supplierSlice.name]: supplierSlice.reducer,
  [productsSlice.name]: productsSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
  [refillSlice.name]: refillSlice.reducer,
  [shopSlice.name]: shopSlice.reducer,
}

export interface RootState {
  [loginSlice.name]: LoginState
  [supplierSlice.name]: SupplierState
  [productsSlice.name]: ProductsState
  [ordersSlice.name]: OrdersState
  [orderSlice.name]: OrderState
  [refillSlice.name]: RefillState
  [shopSlice.name]: ShopState
}
