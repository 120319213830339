import { Alert, StyleSheet, View } from 'react-native'
import { Button } from '@starter/component/Button'
import { colors } from '@starter/themes/colors'
import { Controller, useForm } from 'react-hook-form'
import { FC, useEffect, useState } from 'react'
import { firebaseHelper, IFirebaseUser, IUserStatus } from '../helper/firebase'
import { FormText } from '@starter/component/Form/FormText'
import { ModalWrapper } from '@starter/component/Wrapper/ModalWrapper'
import { size } from '@starter/themes/size'
import { Spacing } from '@starter/component/Spacing'
import { Text } from '@starter/component/Text'


interface IUserModalProps {
    isVisible: boolean
    user?: IFirebaseUser
    hideModel: () => void
}

interface IInput {
    id: string
    password: string
}

export const UserModal: FC<IUserModalProps> = ({ user, hideModel, isVisible }) => {
    const { setValue, control, handleSubmit } = useForm<IInput>({
        defaultValues: {
            id: '',
            password: ''
        }
    })
    const [error, setError] = useState<String | undefined>(undefined)

    useEffect(() => {
        if (!user) {
            setValue('id', '')
            setValue('password', '')
        } else {
            setValue('id', user.id)
            setValue('password', user.password)
        }
        setError(undefined)
    }, [isVisible])

    const onPressConfirm = (data: IInput) => {
        const action = !!user ? firebaseHelper.updateUser({ ...user, ...data }) : firebaseHelper.addUser({ ...data, status: 'active' })
        action.then(result => {
            hideModel()
        }).catch(e => {
            setError(e)
        })
    }

    const onChangeStatus = (status: IUserStatus) => {
        if (!user) {
            return
        }
        firebaseHelper.updateUser({ ...user, status: status }).then(result => {
            hideModel()
        }).catch(e => {
            setError(e)
        })
    }

    return (
        <ModalWrapper title='產品資料' hideModel={hideModel} isVisible={isVisible}>
            <View style={styles.container}>
                {(!user || user.status === 'active') && <>
                    <Controller name='id' control={control} render={({ field }) => {
                        return <>
                            <FormText text={field.value} title='ID' placeHolder='ID' onChangeText={(text) => field.onChange(text)} />
                        </>
                    }} />
                    <Controller name='password' control={control} render={({ field }) => {
                        return <FormText text={field.value} title='password' placeHolder='password' onChangeText={(text) => field.onChange(text)} isPaasword />
                    }} />
                    {error &&
                        <>
                            <Text style={{ color: colors.danger }}>{error}</Text>
                            <Spacing height={size[2]} />
                        </>
                    }
                    <Button title='確定' onPress={handleSubmit(onPressConfirm)} />
                    <Spacing height={size[2]} />
                </>}
                {user?.status === 'active' && <Button title='停用帳戶' onPress={() => onChangeStatus('declined')} type='neat' />}
                {user?.status === 'declined' && <Button title='重啟帳戶' onPress={() => onChangeStatus('active')} type='neat' />}
            </View>
        </ModalWrapper>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: size[2],
    },
})
