import * as DocumentPicker from 'expo-document-picker';
import { AccountType, loginActions, loginSeletor } from '@slice/login';
import {
  Alert,
  Image,
  StyleSheet,
  View
  } from 'react-native';
import { Button } from '@starter/component/Button';
import { colors } from '@starter/themes/colors';
import { firebaseHelper } from '../helper/firebase';
import { images } from '@starter/themes/images';
import { LoggedInBottomTabProps } from '../navigation/types';
import { Row } from '@starter/component/Row';
import { size } from '@starter/themes/size';
import { Spacing } from '@starter/component/Spacing';
import { supplierSeletor } from '@slice/supplier';
import { useDispatch, useSelector } from 'react-redux';



export default function ProfileScreen({ navigation }: LoggedInBottomTabProps<'Profile'>) {
  const { suppliers } = useSelector(supplierSeletor)
  const { id, accountType } = useSelector(loginSeletor)
  const dispatch = useDispatch()

  const supplier = suppliers.find((s) => s.ID === String(id))

  const onPressLogout = () => {
    dispatch(loginActions.logout())
  }

  const onPressPickDocument = () => {
    DocumentPicker.getDocumentAsync({ multiple: false, copyToCacheDirectory: true }).then(async (result) => {
      const { type } = result
      if (type === 'success') {
        const { file, mimeType } = result
        if (mimeType === 'application/vnd.ms-excel' && !!file) {
          firebaseHelper.uploadSafeAmountFile(file)
        } else {
          Alert.alert('Wrong file format', undefined, [{ text: 'OK' }])
        }
      }
    })
  }

  const navigateToUpdateRecord = () => {
    navigation.navigate('SafeRecordUploadStatuses')
  }

  const onPressManageUser = () => {
    navigation.navigate('Users')
  }

  return (
    <View style={styles.container}>
      <Image source={images.icon} style={styles.image} />
      <Row title='ID' description={id} />
      <Row title='名稱' description={supplier?.N1L ?? '-'} />
      <Spacing height={size[5]} />
      {accountType === AccountType.ADMIN &&
        <>
          <Button title='帳號管理' onPress={onPressManageUser} type='outline' />
          <Spacing height={size[5]} />
        </>
      }
      {accountType === AccountType.ADMIN &&
        <>
          <Button title='更新安全量文件' onPress={onPressPickDocument} />
          <Spacing height={size[5]} />
          <Button title='更新記錄' onPress={navigateToUpdateRecord} type='neat' />
          <Spacing height={size[10]} />
        </>
      }
      <Button title='登出' onPress={onPressLogout} color={colors.danger} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: size[4],
  },
  image: {
    width: 300,
    height: 300,
    alignSelf: 'center',
    marginBottom: size[5],
  },
})
