import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import { Reducers } from '@slice/index'
import { Screens } from './screens'
import { firebaseHelper } from './helper/firebase'
import { redux } from '@starter'
import useCachedResources from './hooks/useCachedResources'

const { store, persistor } = redux({reducers: Reducers, blacklist: ['refill', 'shop']})

export default function App() {
  const isLoadingComplete = useCachedResources()
  firebaseHelper.initFirebase()

  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Screens />
        </PersistGate>
      </Provider>
    )
  }
}
